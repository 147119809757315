import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectionTrackerService {
  private path = "/objection-tracker";
  constructor(private backendService: BackendService) { }
  saveObjection(postParams: any) {
    return this.backendService.post(this.path, postParams);
  }

  deleteObjection (data:any){

    var payload = {
      selectedPaper: data.selectedPaper,
      selectedSet: data.selectedSet,
      objectionQuestion: data.objectionQuestion
    }
    return this.backendService.post(this.path + "/delete_ot", payload);
  }
  getObjection() {
    return this.backendService.get(this.path);
  }

  lock (data:any){ 
    return this.backendService.post(this.path + "/lock", data);
  }

  isSubmitted (data:any){ 
    return this.backendService.post(this.path + "/submitted", data);
  }

  clear (data:any){
    return this.backendService.post(this.path + "/clear", data);
  }
  find (data:any){
    return this.backendService.post(this.path + "/find", data);
  }
}
