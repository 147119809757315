import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

   
  constructor(private backendService: BackendService) { }



  getHallTicketStatus() {
    return this.backendService.get("/application/hallticket");
  }

 
  

  saveCandidateStatus(tabid: string) {
    return this.backendService.post("/application", { tabid: tabid });
  }


  getApplicationTabs() {
    return this.backendService.get("/application/app-tabs");
  }

  getCandidateExamsDashboard() {
    return this.backendService.get("/dashboard/exams");
  }

  getCandidateExamsDashboardAll() {
    return this.backendService.get("/dashboard/examsall");
  }

  submitApplication(tabid: any) {
    return this.backendService.post("/application/submit", { tabid: tabid });
  }




  getSubmissionStatus() {
    return this.backendService.get("/application/status");
  }



  upadateDownloadCount(examid: any, examname: any, count: any) {
    return this.backendService.post("/application/count", { examid: examid, examname: examname, downloadcount: count });

  }

}
