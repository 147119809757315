import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class CourseExamService {

  constructor(private backendService: BackendService) { }

  private path = '/candidate-exams';
  private examPath = '/exam';

  saveCandidateExam(examid: any, tabid: any) {

    return this.backendService.post(this.path, { examid: examid, tabid: tabid });
  }


  getCandidateExam() {

    return this.backendService.get(this.path);
  }

  getExamList() {

    return this.backendService.get(this.examPath);
  }

  getCandidateExambyId(examid) {
    return this.backendService.get(this.path + '/' + examid);
  }

}