import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class EligibilityCriteriaService {

  constructor(private backendService: BackendService) { }

  private path = '/eligibility-criteria';


  saveEligibilityCriteria(eliCriteria, tabid: any) {

    return this.backendService.post(this.path, { data: eliCriteria, tabid: tabid });

  }

  getEligibilityCriteria() {

    return this.backendService.get(this.path);
  }


}