import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class TestCityService {

  constructor(private backendService:BackendService) { }
  
  
  private pathb = '/test-city';

  getTestcity() {
    
    return this.backendService.get(this.pathb + "/centers");
  }

  saveCity(testCity:any, tabid:string) {
    return this.backendService.post(this.pathb ,{data:testCity, tabid: tabid});
  }

  getCity() {
    return this.backendService.get(this.pathb);
  }

  searchTestCenter(state:any, city:any) {
    return this.backendService.post(this.pathb +'/search' ,{state:state.state, city: city.city});
  }

}