export * from './core/core.service';
export * from './backend/backend.service';
export  * from './authentication/authentication.service';
export * from './home/home.service';
export * from './interceptor/interceptor.service'; 
export  * from './storage/storage.service';
export  * from './captcha/captcha.service';
export  * from './personal-details/personal-details.service';
export  * from './occupational-details/occupational-details.service';
export  * from './educational-details/educational-details.service';
export * from './constant/constant.service';
export  * from './eligibility-criteria/eligibility-criteria.service';
export  * from './exam-selection/exam-selection.service';
export  * from './course-exam/course-exam.service'; 
export * from './authguard/authguard.service';
export * from './test-city/test-city.service'; 
export * from './windows/windows.service';
export * from './payment/payment.service';
export * from './resetpassword/resetpassword.service';
export * from './objection-tracker/objection-tracker.service';
// export * from './next-exam/next-exam.service';
export * from './reports/reports.service';
export * from './application/application.service';
export * from './alert/alert.service';
export * from './candidate-activity/candidate-activity.service';
export * from './store.service';
