import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class OcuupationalDetailsService {

  constructor(private backendService: BackendService) { }

  private path = '/occupational-details';

  saveOccupationalDetails(data) {
    return this.backendService.post(this.path, data);
  }

  saveOccupationalStatus(occStatus: any, tabId: string) {
    return this.backendService.post(this.path + '/status', { occStatus: occStatus, tabid: tabId });
  }



  getOccupationalDetails() {
    return this.backendService.get(this.path);
  }

  getOccupationalStatus() {
    return this.backendService.get(this.path + "/status");
  }

  deleteOccupationalDetails(occId) {
    return this.backendService.get(this.path + '/delete/' + occId);
  }


}