import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class PersanolDetailsService {

  constructor(private backendService: BackendService) { }

  private path = '/personal-details';




  getAsOnDate(data:any) {

    return this.backendService.post(this.path  +"/asondate", data);
  }


  savePersonalDetails(currentPerDetail, tabid: any) {

    return this.backendService.post(this.path, { data: currentPerDetail, tabid: tabid });
  }


  getPersonalDetails() {

    return this.backendService.get(this.path);
  }

  getUserDetails() {
    return this.backendService.get(this.path + "/userdetails");

  }

  getCategory() {
    return this.backendService.get(this.path + '/category');
  }


}